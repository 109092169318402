import { useEffect } from 'react'
import { navigate } from 'gatsby'

const Features = () => {
  useEffect(() => {
    navigate('/features/idea-plan')
  }, [])
  return null
}

export default Features
